import { i18n } from '@/i18n';
function getOptions() {
  let options = [];
  for (let i = 0; i < 6; i++) {
    options.push({
      score: i,
      title: i18n(
        `entities.scoreOutput.types.pronunciation.titles.[${i}]`,
      ),
      explanation: i18n(
        `entities.scoreOutput.types.pronunciation.explanations.[${i}]`,
      ),
    });
  }
  return options;
}
export default [
  {
    title: 'Pronunciation',
    field: 'pronunciation',
    options: getOptions(),
  },
];
