<template>
  <div class="result-item result-read-aloud">
    <div class="exam-grid">
      <div>
        <div class="g-item">
          <el-card class="box-card">
            <h4 class="box-title">
              {{ getQuestionTitle }}
            </h4>
            <p :class="checkRTL.trim()">
              {{ textQuestion }}
            </p>
          </el-card>
        </div>
        <div class="g-item">
          <el-card class="box-card">
            <h4 class="box-title">
              {{ getResponseTitle }}
            </h4>
            <vue-editor
              ref="vue-editor-content"
              v-model="editorContent"
              :editorToolbar="customToolbar"
            ></vue-editor>
            <small
              class="spelling-mistake"
              @click="spellingExplanation = !spellingExplanation"
            >
              {{ getSpellingTitle }}
              <i class="el-icon-fa-question-circle"></i>
            </small>
            <el-tree
              v-if="spellingExplanation"
              :data="getSpellingExplanation"
              default-expand-all
            ></el-tree>
          </el-card>
        </div>
      </div>
      <div>
        <div class="g-item">
          <score-form :options="options" :formschema="formSchema" :is-def-vals="waitForDefaultValues()"/>
        </div>
        <answer-statistics type="wwc"/>
      </div>
    </div>
  </div>
</template>

<script>
import ScoreForm from '../components/scoreForm';
import { ScoreOutputModel } from '../score-output-model';
import { FormSchema } from '../../../shared/form/form-schema';
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import { VueEditor } from 'vue2-editor';
import AnswerStatistics from "../components/answerStatistics";
import { mapMutations } from 'vuex';
import UtilsText from "../../../shared/utils-text";
const { fields } = ScoreOutputModel;

export default {
  name: 'score-write-essay',
  components: {AnswerStatistics, ScoreForm, VueEditor },
  data() {
    return {
      editorContent: '',
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: 'yellow' }],
        [{ align: 'right' }],
        [{ direction: 'rtl' }],
        ['clean']
      ],
      spellingExplanation: false
    };
  },

  methods: {
    ...mapMutations({
      setRecord: 'scoreOutput/form/SET_RECORD',
    }),
    buildOptions(group, count) {
      let options = [];
      for (let i = 0; i < count; i++) {
        options.push({
          score: i,
          title: i.toString(),
          explanation: i18n(
            `entities.scoreOutput.types.writeEssay.${group}.explanations[${i}]`
          )
        });
      }
      return options;
    },
    waitForDefaultValues(){
      if(!this.record.score){//Eger varsa hoca daha once notlandırmıstır
        var _record = this.record;
        if(this.wordCount < 120 || this.wordCount > 380){
          _record.score = {
            form: 0
          };
        }else if((this.wordCount >= 120 && this.wordCount <= 199)
                || (this.wordCount >= 301 && this.wordCount <= 380)){
          _record.score = {
            form: 1
          };
        }
        else if(this.wordCount <= 300 || this.wordCount <= 200){
          _record.score = {
            form: 2
          };
        }
        this.setRecord(_record)
        return true;
      }
      return false;
    },
  },

  computed: {
    ...mapGetters({
      record: 'scoreOutput/form/record',
      textAnswer: 'scoreOutput/form/textAnswer',
      textQuestion: 'scoreOutput/form/textQuestion',
      checkRTL: 'scoreOutput/form/checkRTL',
    }),
    wordCount() {
      return UtilsText.wordCount(this.record.examOutput.answer.input);
    },
    getQuestionTitle() {
      return i18n('entities.scoreOutput.fields.questionTitle');
    },
    getResponseTitle() {
      return i18n('entities.scoreOutput.fields.responseTitle');
    },
    getSpellingTitle() {
      return i18n('entities.scoreOutput.spelllangexplanations.title');
    },
    getSpellingExplanation() {
      const spellingLang = [];
      for (let i = 0; i < 6; i++) {
        spellingLang.push({
          label: i18n(
            `entities.scoreOutput.spelllangexplanations.explanations[${i}]`
          )
        });
      }
      return spellingLang;
    },
    answer() {
      const answerParagraphs = this.textAnswer.split('\n');
      return answerParagraphs;
    },
    options() {
      fields.content.data = {
        description: i18n(
          'entities.scoreOutput.types.writeEssay.content.description'
        ),
        options: this.buildOptions('content', 4)
      };
      fields.form.data = {
        options: this.buildOptions('form', 3)
      };
      fields.grammar.data = {
        options: this.buildOptions('grammar', 3)
      };
      fields.vocabulary.data = {
        options: this.buildOptions('vocabulary', 3)
      };
      fields.development.data = {
        options: this.buildOptions('development', 3)
      };
      fields.linguistic.data = {
        options: this.buildOptions('linguistic', 3)
      };
      fields.spelling.data = {
        options: this.buildOptions('spelling', 3)
      };
      const options = [
        fields.content,
        fields.form,
        fields.grammar,
        fields.vocabulary,
        fields.development,
        fields.linguistic,
        fields.spelling
      ];

      return options;
    },
    formSchema() {
      const formSchema = new FormSchema(this.options);
      return formSchema;
    },
    getBrowserLanguage() {
      const lang = navigator.language;
      return lang;
    }
  },
  created() {
    this.editorContent = this.textAnswer;
  },
  mounted() {
    if (this.checkRTL == ' rtl ') {
      this.$refs['vue-editor-content'].quill.format('direction', 'rtl');
      this.$refs['vue-editor-content'].quill.format('align', 'right');
    }
  }
};
</script>

<style scoped>
.spelling-mistake:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
