<template>
  <div>
    <el-card class="box-card result-options">
      <el-form
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
        :class="checkRTL"
      >
        <el-collapse v-model="openedTabIndex" accordion>
          <el-collapse-item
            :name="index"
            v-for="(option, index) in options"
            :key="index"
          >
            <template slot="title">
              <h3 :class="model[option.name] === '' ? 'empty' : 'filled'">
                {{ option.label }}
                <el-popover
                  v-if="option.data.description"
                  placement="top-start"
                  width="250"
                  trigger="hover"
                  :content="option.data.description"
                >
                  <span slot="reference" class="el-icon-info" />
                </el-popover>
              </h3>
            </template>
            <score-option :option="option" :model="model" @input="checkInput" />
          </el-collapse-item>
        </el-collapse>
        <el-form-item class="scoreBtnBox">
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doReset"
              icon="el-icon-fa-undo"
            >
              <app-i18n code="common.reset"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import ScoreOption from './scoreOption';
import { mapGetters, mapActions } from 'vuex';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';

export default {
  name: 'score-form',
  components: { ScoreOption },
  // editordeki verileri localstorage'a yazdirmak icin 'editorContent' gelen degeri props'a ekle
  props: ['options', 'formschema','isDefVals'],
  data() {
    return {
      openedTabIndex: 0, //yuklenirken ilk sekme acik gelecek.
      rules: null,
      model: null
    };
  },
  created() {
    //console.log("Default Record: " + this.isDefVals)
    //console.log("RECORD in form: " + JSON.stringify(this.record.score))
    this.model = this.formschema.initialValues(this.record.score || {});
    //console.log("MODEL in form: " + JSON.stringify(this.model))
    this.rules = this.formschema.rules();
  },
  computed: {
    ...mapGetters({
      record: 'scoreOutput/form/record',
      saveLoading: 'scoreOutput/form/saveLoading',
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      checkRTL: 'scoreOutput/form/checkRTL',
      checkTextRight: 'scoreOutput/form/checkTextRight',
      rtlAndPadding: 'scoreOutput/form/rtlAndPadding'
    })
  },
  methods: {
    ...mapActions({
      doUpdate: 'scoreOutput/form/doUpdate',
      // checkLocalStroge: 'scoreOutput/form/checkLocalStroge' // Editordeki icerigi LocalStorage'den cekmek icin
    }),
    doCancel() {
      routerAsync().push('/score-output');
    },
    async doSubmit() {
      // Editordeki verileri LocalStorage'e yazdirmak icin
      // await this.addEditorTextInLocalStorage();
      const score = await this.validateAndPrepData();
      await this.doUpdate({
        id: this.record.id,
        values: { score }
      });
    },
    async validateAndPrepData() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        Message.error('Validation Error');
      }
      const score = this.formschema.cast(this.model);
      return score;
    },
    doReset() {
      this.model = this.formschema.initialValues(this.record.score);
    },
    checkInput() {
      let checkNext = true;
      for (let key in this.model) {
        if (this.model[key] === '') {
          checkNext = false;
          break;
        }
      }
      if (checkNext) {
        this.showNext = true;
      } else {
        // sonuncu değilse 1 artır
        this.openedTabIndex < this.options.length - 1
          ? this.openedTabIndex++
          : null;
      }
    },
    // Editordeki verileri LocalStorage'e yazdirmak icin
    // addEditorTextInLocalStorage() {
    //   if (this.editorContent) {
    //     let data = [];
    //     let myObj = {
    //       scoreOutputId: this.record.id,
    //       editedAnswer: this.editorContent
    //     };
    //     const storageEditedAnswers = JSON.parse(
    //       localStorage.getItem('editedAnswers')
    //     );
    //     if (storageEditedAnswers) {
    //       storageEditedAnswers.forEach((key) => {
    //         if (key.scoreOutputId != myObj.scoreOutputId) {
    //           data.push(key);
    //         }
    //       });
    //     }

    //     data.push(myObj);
    //     localStorage.setItem('editedAnswers', JSON.stringify(data));
    //   }
    // }
  }
};
</script>

<style scoped>
.scoreBtnBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 0;
  margin-top: 2rem;
}

.doPublish {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
</style>

<style>
.rtl i.el-collapse-item__arrow.el-icon-arrow-right {
  margin: 0;
}
.rtl .el-collapse-item__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.rtl .el-collapse-item__arrow.is-active {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.rtl .el-icon-arrow-right:before {
  content: '\e6de';
}

.rtl label.el-radio {
  margin-right: 0;
  margin-left: 30px;
}

.rtl span.el-radio__label {
  padding-left: 0;
  padding-right: 10px;
}

.rtl .el-button [class*='el-icon-'] + span {
  margin-right: 5px;
  margin-left: 0px;
}
</style>
