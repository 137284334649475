import * as yup from 'yup';
import { i18n } from '@/i18n';
import GenericField from '@/shared/fields/generic-field';

export default class RadioGroup extends GenericField {
  constructor(name, label, { required = false } = {}) {
    super(name, label);
    this.data = {
      description: 'ceviri dosyasindan gelmeli', //todo
      options: [
        {
          score: 0,
          title: '', //gorunen
          explanation: '', //popover
        },
      ],
    }; //initialize sonrasi set edilir.
    this.required = required;
  }

  forFormCast() {
    let yupChain = yup
      .number()
      .required()
      .positive()
      .integer();
    return yupChain;
  }

  forFormInitialValue(value) {
    //0,1,2 gibi data gelir.
    if (value == null)
      //0 deger 0 olarak donsun diye if(value) deme
      return '';
    return value;
  }

  forFormRules() {
    let output = {};
    if (this.required) {
      output = {
        required: !!this.required,
        message: i18n('validation.mixed.required').replace(
          '${path}',
          this.label,
        ),
        trigger: 'change',
      };
    }
    return output;
  }
}
