<template>
  <el-card class="box-card result-src">
    <div :class="('score-audio' + checkReverse).trim()">
      <h4 class="box-title black">{{ title }}</h4>
      <div class="controls">
        <i
          ref="control"
          class="el-icon-fa-play-circle control"
          @click="toggle"
        ></i>
      </div>
    </div>
    <div class="score-waveform">
      <!--    <audio controls :src="src" />-->
      <av-waveform
        ref="scoreAudio"
        :audio-src="src"
        played-line-color="#388e3c"
        :audio-controls="false"
        :cors-anonym="true"
        :playtime="true"
        noplayed-line-color="#a51e36"
      />
    </div>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'scorePlayer',
  data() {
    return {
      audio: null,
      isPlaying: false,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
  mounted() {
    //this.audio = document.getElementsByTagName('audio')[0];
    this.audio = this.$refs.scoreAudio.audio;
    this.audio.onended = this.onEnded;
    this.control = this.$refs.control;
    //console.log(this.audio);
  },
  // updated() {
  //   console.log('updated');
  //   this.audio = this.$refs.scoreAudio.audio;
  // },
  computed: {
    ...mapGetters({
      isRTL: 'scoreOutput/form/isRTL',
    }),
    checkReverse() {
      return this.isRTL === true ? ' reverseRow ' : '';
    },
  },
  methods: {
    toggle() {
      if (this.isPlaying) {
        this.pause();
      } else {
        this.play();
      }
    },
    pause() {
      this.audio.pause();
      this.onEnded();
    },
    play() {
      this.audio.play();
      this.control.classList.add('el-icon-fa-pause-circle');
      this.control.classList.remove(
        'el-icon-fa-play-circle',
      );
      this.isPlaying = true;
    },
    onEnded() {
      //console.log('onEnded');
      this.control.classList.remove(
        'el-icon-fa-pause-circle',
      );
      this.control.classList.add('el-icon-fa-play-circle');
      this.isPlaying = false;
    },
  },
};
</script>

<style scoped>
h4 {
  margin-bottom: 0 !important;
}
</style>

<style>
.reverseRow {
  flex-direction: row-reverse;
}
</style>
