import { i18n } from '@/i18n';
import { GenericModel } from '../../shared/model/generic-model';
import RadioGroup from '../../shared/fields/radio-group';
import pronunciation from './static/pronunciation';
import oralFluency from './static/oralFluency';
import IdField from '../../shared/fields/id-field';
import EnumeratorField from '../../shared/fields/enumerator-field';
import { UserField } from '../auth/user-field';
import { ExamOutputField } from '../exam-output/exam-output-field';
import DateTimeField from '../../shared/fields/date-time-field';
import StringField from '../../shared/fields/string-field';

function label(name) {
  return i18n(`entities.scoreOutput.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.scoreOutput.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  scoreStatus: new EnumeratorField('scoreStatus', label('scoreStatus'), [
    {
      id: '0',
      label: enumeratorLabel('scoreStatus', 'draft')
    },
    {
      id: '1',
      label: enumeratorLabel('scoreStatus', 'saved')
    },
    {
      id: '2',
      label: enumeratorLabel('scoreStatus', 'published')
    },
    {
      id: '3',
      label: enumeratorLabel('scoreStatus', 'evaluated') //EVALUATED
    }
  ]),
  teacher: UserField.relationToOne('teacher', label('teacher'), {
    required: true
  }),
  examOutputStr: new StringField('examOutput', label('examOutput'), {
    required: true
  }),
  examOutput: ExamOutputField.relationToOne(
    'examOutputId',
    label('examOutput'),
    {
      required: true
    }
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  score: new StringField('score', label('score'), {}),
  //------------- SCORE JSON -----------------------------
  content: new RadioGroup('content', label('content'), {
    required: true
  }),
  pronunciation: new RadioGroup('pronunciation', label('pronunciation'), {
    required: true
  }),
  oralFluency: new RadioGroup('oralFluency', label('oralFluency'), {
    required: true
  }),
  form: new RadioGroup('form', label('form'), {
    required: true
  }),
  grammar: new RadioGroup('grammar', label('grammar'), {
    required: true
  }),
  vocabulary: new RadioGroup('vocabulary', label('vocabulary'), {
    required: true
  }),
  development: new RadioGroup('development', label('development'), {
    required: true
  }),
  linguistic: new RadioGroup('linguistic', label('linguistic'), {
    required: true
  }),
  spelling: new RadioGroup('spelling', label('spelling'), {
    required: true
  })
};

export class ScoreOutputModel extends GenericModel {
  static get fields() {
    fields.pronunciation.data = {
      options: pronunciation[0].options
    };
    fields.oralFluency.data = {
      options: oralFluency[0].options
    };
    return fields;
  }
}
