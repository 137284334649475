<template>
  <div class="result-item result-read-aloud">
    <div class="exam-grid">
      <div>
        <div class="g-item">
          <score-player
            key="score-ra-1"
            :src="src"
            :title="getResponseTitle"
          />
        </div>
        <div class="g-item">
          <el-card class="box-card">
            <h4 class="box-title">
              {{ getQuestionTitle }}
            </h4>
            <p :class="checkRTL.trim()">
              {{ textQuestion }}
            </p>
          </el-card>
        </div>
        <answer-statistics :key="`sta-${record.id}`" />
      </div>
      <div>
        <div class="g-item">
          <score-form
            :options="options"
            :formschema="formSchema"
            :key="record.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoreForm from '../components/scoreForm';
import ScorePlayer from '../components/scorePlayer';
import { ScoreOutputModel } from '../score-output-model';
import { FormSchema } from '../../../shared/form/form-schema';
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import AnswerStatistics from "../components/answerStatistics";

const { fields } = ScoreOutputModel;
export default {
  name: 'score-read-aloud',
  components: {AnswerStatistics, ScorePlayer, ScoreForm },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      record: 'scoreOutput/form/record',
      textQuestion: 'scoreOutput/form/textQuestion',
      src: 'scoreOutput/form/srcAnswer',
      checkRTL: 'scoreOutput/form/checkRTL',
    }),
    getQuestionTitle() {
      return i18n(
        'entities.scoreOutput.fields.questionTitle',
      );
    },
    getResponseTitle() {
      return i18n(
        'entities.scoreOutput.fields.responseTitle',
      );
    },
    buildOptions() {
      let options = [];
      for (let i = 0; i < 6; i++) {
        options.push({ score: i, title: i.toString() });
      }
      return options;
    },
    options() {
      fields.content.data = {
        description: i18n(
          'entities.scoreOutput.types.readAloud.content.description',
        ),
        options: this.buildOptions,
      };
      let options = [
        fields.content,
        fields.pronunciation,
        fields.oralFluency,
      ];
      return options;
    },
    formSchema() {
      const formSchema = new FormSchema(this.options);
      return formSchema;
    },
  },
};
</script>

<style scoped></style>
