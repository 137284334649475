<template>
  <div class="result-item result-read-aloud">
    <div class="exam-grid">
      <div>
        <div class="g-item">
          <score-player
            :src="srcAnswer"
            key="score-di-1"
            :title="getResponseTitle"
          />
        </div>
        <div class="g-item">
          <el-card class="box-card result-img">
            <h4 class="box-title">
              {{ getQuestionTitle }}
            </h4>
            <div class="image-item">
              <img
                :src="srcQuestion"
                class="w-100"
                alt=""
              />
            </div>
          </el-card>
        </div>
        <answer-statistics />
      </div>
      <div>
        <div class="g-item">
          <score-form
            :options="options"
            :formschema="formSchema"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoreForm from '../components/scoreForm';
import ScorePlayer from '../components/scorePlayer';
import { ScoreOutputModel } from '../score-output-model';
import { FormSchema } from '../../../shared/form/form-schema';
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import AnswerStatistics from "../components/answerStatistics";

const { fields } = ScoreOutputModel;
export default {
  name: 'scoreDescribeImage',
  components: {AnswerStatistics, ScorePlayer, ScoreForm },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      record: 'scoreOutput/form/record',
      srcAnswer: 'scoreOutput/form/srcAnswer',
      srcQuestion: 'scoreOutput/form/srcQuestion',
    }),
    getQuestionTitle() {
      return i18n(
        'entities.scoreOutput.fields.questionTitle',
      );
    },
    getResponseTitle() {
      return i18n(
        'entities.scoreOutput.fields.responseTitle',
      );
    },
    buildOptions() {
      let options = [];
      for (let i = 0; i < 6; i++) {
        options.push({
          score: i,
          title: i.toString(),
          explanation: i18n(
            `entities.scoreOutput.types.describeImage.content.explanations[${i}]`,
          ),
        });
      }
      return options;
    },

    options() {
      fields.content.data = {
        description: i18n(
          'entities.scoreOutput.types.describeImage.content.description',
        ),
        options: this.buildOptions,
      };
      const options = [
        fields.content,
        fields.pronunciation,
        fields.oralFluency,
      ];
      return options;
    },
    formSchema() {
      const formSchema = new FormSchema(this.options);
      return formSchema;
    },
  },
};
</script>

<style>
.image-item > img {
  display: block;
  margin: auto;
}
</style>
