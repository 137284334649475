<template>
    <div class="g-item">
        <el-card class="box-card">
            <h4 class="box-title">
                {{ answerStatisticsTitle }}
            </h4>
            <p v-if="answerStatistics.wordCount" :class="checkRTL.trim()">
                {{ wordCountTitle }}: {{answerStatistics.wordCount}}
            </p>
            <p v-if="answerStatistics.timeSpent" :class="checkRTL.trim()">
                {{ timeSpentTitle }}: {{answerStatistics.timeSpent}} {{timeSpentUnit}}
            </p>
        </el-card>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import UtilsText from "../../../shared/utils-text";
    import { i18n } from '@/i18n';
    export default {
        name: "answerStatistics",
        props: {
            type: {
                type: String,
                default: "notWwc"
            },
        },
        computed: {
            ...mapGetters({
                _answerStatistics: 'scoreOutput/form/answerStatistics',
                checkRTL: 'scoreOutput/form/checkRTL',
                record: 'scoreOutput/form/record',
            }),
            wordCount(){
                return UtilsText.wordCount(this.record.examOutput.answer.input)
            },
            answerStatistics(){
                const _statistics = this._answerStatistics
                if(this.type === "wwc"){ //withWordCount
                    _statistics.wordCount = this.wordCount
                    if(_statistics.timeSpent){
                        _statistics.timeSpent = Math.round(_statistics.timeSpent/60)
                    }
                }
                return _statistics
            },
            answerStatisticsTitle(){
                return i18n('entities.scoreOutput.fields.answerStatisticsTitle');
            },
            timeSpentTitle() {
                return i18n('entities.scoreOutput.fields.timeSpentTitle');
            },
            timeSpentUnit() {
                if(this.type === "wwc"){
                    return i18n('entities.scoreOutput.fields.timeSpentUnitMinutes');
                }
                return i18n('entities.scoreOutput.fields.timeSpentUnit');
            },
            wordCountTitle() {
                return i18n('entities.scoreOutput.fields.wordCountTitle');
            }
        }
    };
</script>

<style scoped />
