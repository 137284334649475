<template>
  <div class="result-item result-read-aloud">
    <div class="exam-grid">
      <div>
        <div class="g-item">
          <score-player
            :src="srcQuestion"
            key="score-asq-1"
            :title="getQuestionTitle"
          />
        </div>
        <div class="g-item">
          <score-player
            :src="srcAnswer"
            key="score-asq-2"
            :title="getResponseTitle"
          />
        </div>
        <div v-if="answerHint" class="g-item">
          <el-card class="box-card">
            <h4 class="box-title">
              {{getAnswerHintTitle}}
            </h4>
            <p :class="checkRTL.trim()">
              {{answerHint}}
            </p>
          </el-card>
        </div>
        <answer-statistics />
      </div>
      <div>
        <div class="g-item">
          <score-form
            :options="options"
            :formschema="formSchema"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScoreForm from '../components/scoreForm';
import ScorePlayer from '../components/scorePlayer';
import { ScoreOutputModel } from '../score-output-model';
import { FormSchema } from '../../../shared/form/form-schema';
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import AnswerStatistics from "../components/answerStatistics";

const { fields } = ScoreOutputModel;
export default {
  name: 'score-answer-short-question',
  components: {AnswerStatistics, ScorePlayer, ScoreForm },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      record: 'scoreOutput/form/record',
      srcAnswer: 'scoreOutput/form/srcAnswer',
      srcQuestion: 'scoreOutput/form/srcQuestion',
      answerHint: 'scoreOutput/form/answerHint',
      checkRTL: 'scoreOutput/form/checkRTL'
    }),
    getAnswerHintTitle(){
      return i18n(
              'entities.scoreOutput.fields.answerHint',
      );
    },
    getQuestionTitle() {
      return i18n(
        'entities.scoreOutput.fields.questionTitle',
      );
    },
    getResponseTitle() {
      return i18n(
        'entities.scoreOutput.fields.responseTitle',
      );
    },
    buildOptions() {
      let options = [];
      for (let i = 0; i < 2; i++) {
        options.push({
          score: i,
          title: i.toString(),
          explanation: i18n(
            `entities.scoreOutput.types.answerShortQuestion.content.explanations[${i}]`,
          ),
        });
      }
      return options;
    },
    options() {
      fields.content.data = {
        description: i18n(
          'entities.scoreOutput.types.answerShortQuestion.content.description',
        ),
        options: this.buildOptions,
      };
      const options = [fields.content];
      return options;
    },
    formSchema() {
      const formSchema = new FormSchema(this.options);
      return formSchema;
    },
  },
};
</script>

<style></style>
