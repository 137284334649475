<template>
  <div class="single-option">
    <el-form-item
      :class="
        option.data.options.length > 3 ? 'has-multi' : ''
      "
      :prop="option.name"
    >
      <el-radio-group v-model="model[option.name]">
        <el-radio
          v-for="(item, index) in option.data.options"
          :key="index"
          :label="item.score"
          @change="onChange()"
          v-popover="
            `${item.title.replace(' ', '')}-${index}`
          "
        >
          <el-popover
            placement="top-start"
            width="250"
            trigger="hover"
            :ref="`${item.title.replace(' ', '')}-${index}`"
            :content="item.explanation"
            :disabled="!item.explanation"
            :openDelay="300"
          />
          <span>{{ item.title }}</span>
        </el-radio>
      </el-radio-group>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'score-option',
  props: {
    option: {
      type: Object,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('input');
    },
  },
};
</script>

<style>
.single-option {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.single-option > h3 {
  margin-top: 0.25rem;
  padding: 0.5rem;
  color: #a51e36 !important;
  background-color: #f0f2f5;
  font-size: 0.9rem;
  border: 1px solid #efefef;
  border-radius: 0.25rem;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;
  /*border sebebiyle %100 olunca yatay scrollbar cikiyor.*/
  width: 90%;
}

.single-option > div {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.el-radio__inner {
  border: 1px solid #8f9399;
}
</style>
