import { ExamOutputService } from '@/modules/exam-output/exam-output-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class ExamOutputField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/exam-output',
      Permissions.values.examOutputRead,
      ExamOutputService.listAutocomplete,
      record => {
        if (!record) {
          return null;
        }
        //record = {id: record};
        return {
          id: record,
          label: record
        };
      },
      options
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/exam-output',
      Permissions.values.examOutputRead,
      ExamOutputService.listAutocomplete, //not used in assign readers
      record => {
        if (!record || !record.id) {
          return null;
        }

        return {
          id: record.id,
          label: record.label //?
        };
      },
      options
    );
  }
}
