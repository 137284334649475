<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/score-output' }">
        <app-i18n code="entities.scoreOutput.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="questionType">
        <app-i18n :code="'entities.scoreOutput.breadcrumb.'+questionType"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="!questionType">
        <app-i18n code="common.breadcrumb.noData"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-content-page">
      <component
        :key="id"
        v-bind:is="questionType"
        v-if="questionType"
      />
      <div class="no-record" v-if="!questionType">
        <h4>
          <app-i18n code="entities.scoreOutput.noRecord"></app-i18n>
        </h4>
<!--        <router-link :to="{ path: '/score-output' }">-->
<!--          <app-i18n code="entities.scoreOutput.publishRecord"></app-i18n>-->
<!--        </router-link>-->
<!--        <el-button-->
<!--                @click="doPublish"-->
<!--                icon="el-icon-fa-paper-plane"-->
<!--                type="primary"-->
<!--        >-->
<!--          <app-i18n code="entities.scoreOutput.publish"></app-i18n>-->
<!--        </el-button>-->
      </div>
    </div>
  </div>
</template>

<script>
import scoreRetellLecture from '../types/retell-lecture';
import scoreReadAloud from '../types/read-aloud';
import scoreDescribeImage from '../types/describe-image';
import scoreRepeatSentence from '../types/repeat-sentence';
import scoreAnswerShortQuestion from '../types/answer-short-question';
import scoreSummarizeWrittenText from '../types/summarize-written-text';
import scoreWriteEssay from '../types/write-essay';
import scoreSummarizeSpokenText from '../types/summarize-spoken-text';
//import scoreWriteFromDictation from '../types/write-from-dictation';

import { mapActions, mapGetters } from 'vuex';
import { routerAsync } from '@/app-module';
export default {
  name: 'score-base',
  props: ['id'],
  components: {
    scoreReadAloud,
    scoreRepeatSentence,
    scoreDescribeImage,
    scoreRetellLecture,
    scoreAnswerShortQuestion,
    scoreSummarizeWrittenText,
    scoreWriteEssay,
    scoreSummarizeSpokenText,
  },
  data() {
    return {
      pages: [
        'scoreReadAloud',
        'scoreRepeatSentence',
        'scoreDescribeImage',
        'scoreRetellLecture',
        'scoreAnswerShortQuestion',
        'scoreSummarizeWrittenText',
        'scoreWriteEssay',
        'scoreSummarizeSpokenText',
      ],
    };
  },
  computed: {
    ...mapGetters({
      record: 'scoreOutput/form/record',
      findLoading: 'scoreOutput/form/findLoading',
      saveLoading: 'scoreOutput/form/saveLoading',
      questionType: 'scoreOutput/form/questionType',
    }),
  },
  async created() {
    await this.doFindWithQuestion(this.id);
  },
  methods: {
    ...mapActions({
      doFindWithQuestion:
        'scoreOutput/form/doFindWithQuestion',
      doUpdate: 'scoreOutput/form/doUpdate',
      doPublishInScore: 'scoreOutput/form/doPublish',
    }),
    async doPublish() {
      await this.doPublishInScore();
      routerAsync().push('/score-output');
    },
  },
};
</script>

<style>
.box-card {
  color: black;
}
.el-popover {
  word-break: break-word;
  text-align: left;
}

.exam-grid .g-item {
  padding: 0.5rem;
  width: 100%;
  /*margin: auto; yanyana iki gridden biri uzayinca digeri etkileniyor*/
}

.exam-grid h4 {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.exam-grid p {
  font-size: 0.95rem;
  line-height: 1.5;
  text-indent: 0.5rem;
}

.exam-grid audio {
  display: block;
  margin: auto;
}
.exam-grid {
  display: flex;
  justify-content: center;
  align-items: center;
}
.exam-grid > div {
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
}
.g-item h3 {
  color: #3a3d3c;
  font-weight: 400;
}

.no-record h4 {
  color: #3a3d3c;
  font-weight: 400;
  text-align: center;
}

.no-record {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.g-item {
  color: #3a3d3c;
}

.g-item h3 + i {
  font-weight: bold;
}
.g-item h3.empty + i {
  color: #a51e36;
}
.g-item h3.filled + i {
  color: green;
}
.g-item .el-form-item.is-error {
  padding-bottom: 1rem;
}
.g-item h3.empty {
  color: #a51e36;
}
.g-item h3.filled {
  color: green;
}
.g-item .el-form-item.has-multi label.el-radio {
  margin-top: 0.75rem;
}

.score-audio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
.controls .control {
  font-size: 1.75em !important;
  margin: 0 0.5rem;
}
.score-waveform {
  display: flex;
  justify-content: center;
}
</style>
